header {
    position: relative;
    height: 50px;
    padding: 10px;
}

.more_btn {
    color: #a9a9a9;
}

.header-2 {
    position: relative;
}

.title {
    position: relative;
    display: inline-block;
    margin-right: 7vw;
}

.title-logo {
    display: inline-block;
    margin-right: 10px;
}

.title img {
    width: 40px;
    height: 40px;
}

.title-texts{
    display: inline-block;
    position: relative;
    top: -5px;
}

.title.texts{
    position:fixed;
    height: 70px;
    left: 75px;
}

.contacts{
    display: inline-block;
    position: absolute;    
}
.contacts h5 {
    top: 20px;
}
.contacts h3,h5 {
    position: absolute;
    top: 0;
}

.buttons {
    display: inline;
    position: absolute;
    height: 45px;

    right: 0px;
}

button {
    width: 125px;
    height: 45px;
    border-radius: 100px;
    border: 0;
    background-color: #000000;
    color: #fff;
    text-align: center;
    font-weight: bold;
}

button:hover {
    background-color: #fff;
    color: #000000;
}

/* ? */
button:active { 
    background-color: #5c5c5c;
}

button:disabled {
    background-color: #5c5c5c;
    cursor: no-drop;
    color: #000000;
}

.feedback-btn {
    background-color: #c7000f;
    margin-left: 30px;
}

.form-feedback Input{
    margin-bottom: 10px;
}

.form-feedback-title {
    position: relative;
    left: 35%;
    font-size: 20px;
}

@media screen and (max-width: 1000px) {
    header {
        height: auto;
    }
    
    .title {
        display: block;
        margin: 0;
    }

    .title-logo {
        display: block;
        text-align: center;
        margin-right: 0;
    }

    .title-texts {
        display: block;
        text-align: center;
    }

    .title-text-h1 {
        font-weight: bold;
        font-size: 25px;
    }

    .contacts {
        display: block;
        position: relative;
        text-align: center;
    }
    
    .buttons {
        display: block;
        position: relative;
        text-align: center;
        /* scale: 1.2; */
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .all-contacts-btn {
        display: none;
    }

    .contacts-number {
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
    }
}