.about {
    height: 700px;
    width: 100%;
}


.about-2 {
    padding: 40px;
}

.about-title {
    margin-bottom: 20px;
}

.about-title span {
    font-size: 32px;
    font-weight: bold;
    color: #333333;
}

.about-text p {
    font-size: 16px;
    line-height: 1.6;
    color: #666666;
}

