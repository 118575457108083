.products-container {
    margin-top: 40px; /* Adds top margin */
  }
  
  .product-item {
    font-size: 18px; /* Increases font size */
    margin-bottom: 20px; /* Adds space between items */
  }
  
  .product-title {
    font-weight: bold; /* Optional: makes the title bold */
  }