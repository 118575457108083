.nav_element{
    display: block;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.nav_element a {
    color: #000;
}

.nav_element a:hover {
    color: #c7000f;
}

.nav_menu_btn {
    background-color: #eeeeee;
}