.faq-item {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  
.faq-question {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 50px;
  }
  
.faq-answer {
    margin-left: 20px;
    font-size: 20px;
  }

.faq-container {
    margin-top: 10px;
}