.contacts-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.contact-info {
    flex: 1;
    padding-right: 20px;
}

.contact-info h2 {
    margin-bottom: 20px;
    color: #333;
}

.contact-info p {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.5;
}

.map-container {
    flex: 1;
}

.map-container iframe {
    width: 100%;
    height: 400px;
    border-radius: 8px;
}

@media (max-width: 768px) {
    .contacts-container {
        flex-direction: column;
    }

    .contact-info, .map-container {
        width: 100%;
        padding-right: 0;
        margin-bottom: 20px;
    }
}
