body {
    font-family: Exo2, sans-serif;
}

.centered {
    max-width: 1700px;
    margin: auto;
}


/* section 1 */
.home-page--section__title {
    position: relative;
    display: block;
    padding: 10px 80px 92px 80px;
    background-image: linear-gradient(to right, #000 0%, #fff0 57%), linear-gradient(#000000bd 5%, #fff0 36%), url("/src/assets/background1.jpg");
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: left 0px top 0px, left 0px top 0px, left calc(464px + 50%) top calc(44px + 50%);
    background-size: auto, auto, 1600.00px auto;
    background-attachment: scroll, scroll, scroll;
    background-color: #636363;
    height: 700px;
}

.home-page--section__title-2 {
    position: relative;
    display: block;
    width: 100%;
    margin-top: 100px;
}

.home-page--section__title-2-title span{
    font-weight: 900;
    font-size: 62px;
    color: #fff;
    line-height: 57px;
}

.home-page--section__title-2-subtitle span {
    font-size: 	134.4px;
    line-height: 115.2px;
    font-weight: 700;
    color: #fff;
    text-shadow: none;
    margin-bottom: 15px;
}

@media screen and (max-width: 1164px) {
    .home-page--section__title-2-subtitle span {
        font-size: 	100px;
    }
}


@media screen and (max-width: 878px) {
    .home-page--section__title-2-subtitle span {
        font-size: 	50px;
        line-height: 60px;
}
}

.home-page--section__title-2-tagline {
    margin-top: 25px;
}

.home-page--section__title-2-tagline span{
    
    background-color: #c7000f;
    border-radius: 7px;
    color: #fff;
    font-size: 17px;
    padding: 3px 5px 3px 5px;
}

.home-page--section__title-2-work-time-icon {
    display: inline-block;
}

.home-page--section__title-2-work-time-icon svg{
    fill: #fff;
    width: 40px;
    height: 40px;
    margin-bottom: -15px;
    margin-right: 20px;
}

.home-page--section__title-2-work-time-2 {
    margin-top: 20px;
}

.feedback-btn-work-time {
    padding: 10px;
}

.home-page--section__title-2-work-time-text {
    display: inline-block;
    color: #fff;
    font-size: 15cpx;
}

.home-page--section__title-2-work-time-feedback-button {
    display: inline-block;
}

.home-page__rules {
    color: #fff;
    display: block;
    position: relative;
    margin-top: -47px;
    margin-bottom: 47px;
    margin-left: 5vw;
}

.home-page__rules-0 {
    display: grid;
    grid-template-columns: auto auto auto;
}

.home-page__rules-item {
    display: inline-block;
    background-color: #c7000f;
    margin-right: 2vw;
    padding: 18px;
    border-radius: 100px;
    width: auto;
}


.home-page__rules-item-counter {
    color: #fff;
    font-size: 61px;
    font-weight: 700;
    line-height: 51px;
}

.home-page__rules-item-text{
    display: inline-block;
    position: relative;
    top: -7px;
    color: #fff;
    font-size: 15px;
}

@media screen and (max-width: 1244px) {
    .home-page__rules-item-text {
        top: 0px;
    }
}


/* section 2 */

.home-page-section__products {
    position: relative;
    padding: 100px 80px 33px 80px;
    height: auto;
    border-bottom: 3px #e5e5e5 dotted;
}

.home-page-cetion__products-2 {
    position: relative;
    width: 100%;
}

.home-page-section__products-title {
    font-weight: 700;
    color: #000;
    font-size: 80px;
    line-height: 68px;
}


.product-section-list {
    display: inline-grid;
    position: relative;
    width: 100%;
    grid-template-columns: auto auto auto auto;
    margin-top: 50px;
}

.product-section-list-product {
    position: relative;
    display: inline-block;
    margin: 20px 50px 20px 0px;

}

.product-section-list-product-icon {
    display: inline-block;
    background-color: #f0f0f0;
    border: 5px solid #f0f0f0;
    border-radius: 100%;
    transition: 0.3s;
    padding: 20px;
    margin-bottom: 15px;
}

.product-section-list-product-icon:hover {
    background-color: #fff;
    transition: 0.3s;
}

.product-section-list-product-icon svg {
    width: 80px;
    height: 80px;
}

.product-section-list-product-name{
    font-size: 21px;
    line-height: 21px;
    font-weight: 700;
}
.product-section-list-product-description {
    color: #83838399;
    line-height: normal;
    font-size: 14px;
    text-decoration: underline dashed;
    min-height: 50px;
    max-height: 50px;
}

.product-section-list-product-select-btn {
    display: inline-block;
    margin-top: 17px;
    margin-bottom: 17px;
}

.product-section-list-product-select-btn-2 {
    padding: 17px;
    background-color: #000;
    color: #fff;
    border-radius: 100px;
    transition: 0.3s;
}

.product-section-list-product-select-btn-2:hover {
    color: #fff;
    background-color: #c7000f;
    transition: 0.3s;
}


/* section 3 */
.home-page-section__about {
    padding: 30px;
}

.home-page-section__about-2 {
    display: grid;
    grid-template-columns: auto auto auto;
    position: relative;
}

.home-page-section__about-p1 {
    display: inline-block;
    position: relative;
    max-width: 500px;
    margin-right: 45px;
}

.home-page-section__about-p1-title {
    display: block;
}

.home-page-section__about-p1-title span{
    font-size: 80px;
    line-height: 68px;
    margin-top: 0px;
    font-weight: bold;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    display: block;
    position: relative;
    color: #000;
}

.home-page-section__about-p1-title-2 {
    font-size: 20px;
    line-height: 21px;
    font-weight: 700;
    color: #c7000f;
}

.home-page-section__about-p1-title-3{
    font-size: 15px;
    color: #000000bd;
}

.home-page-section__about-p1-button {
    margin-top: 30px;
    margin-bottom: 45px;
    top: 30px
}

.home-page-section__about-p1-button a{
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    color: #000;
    font-weight: 100;
    padding: 12px;
    transition: 0.3s;
}

.home-page-section__about-p1-button a:hover{
    background-color: #e5e5e5;
    transition: 0.3s;
}

.home-page-section__about-p1-work-time-icon {
    display: inline-block;
    border: 7px solid #f2f2f2;
    padding: 10px;
    border-radius: 100%;
    margin-right: 20px;
}

.home-page-section__about-p1-work-time-icon svg{
    fill: #c7000f;
}

.home-page-section__about-p1-work-time-texts {
    display: inline-block;
    position: relative;
    top: 10px;
}

.home-page-section__about-p1-work-time-text-1 {
    position: relative;
    display: block;
}
.home-page-section__about-p1-work-time-text-1 span{
    font-size: 47px;
    color: #000;
    font-weight: 900;
    line-height: 48px;
}

.home-page-section__about-p1-work-time-text-2 {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
}
.home-page-section__about-p1-work-time-text-2 {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 17px;
    color: #c7000f;
}
/* p-2 */
.home-page-section__about-p2 {
    position: relative;
    display: inline-block;
}

.home-page-section__about-p2-image {
    max-width: 533px;
    width: auto;
    height: 500px;
}

.home-page-section__about-p2-image-img img {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 355px;
    filter: saturate(0%);
    z-index: 0;
}

.home-page-section__about-p2-image-text {
    position: relative;
    display: inline-block;
    background-color: #c7000f;
    border-radius: 45px;
    padding: 23px 40px 23px 40px;
    top: -300px;
    left: -50px;
    z-index: 1;
}

.home-page-section__about-p2-image-text-1 span{
    color: #fff;
    font-size: 45px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 40px;
}

.home-page-section__about-p2-image-text-2 span{
    color: #ffffffa3;
    font-size: 29px;
    font-weight: 100;
    text-transform: uppercase;
}

/* p-3 */

.home-page-section__about-p3 {
    display: inline-block;
    position: relative;
    max-width: 650px;
    width: 100%;
}

.home-page-section__about-p3-item {
    margin-bottom: 18px;
}

.home-page-section__about-p3-icon {
    display: inline-block;
    position: relative;
    border: 5px solid #f2f2f2;
    background-color: #f2f2f2;
    border-radius: 100%;
    padding: 16.3px;
}

.home-page-section__about-p3-icon-1 svg {
    width: 60px;
    height: 60px;
}

.home-page-section__about-p3-text {
    display: inline-block;
    position: relative;
}

.home-page-section__about-p3-text-1 {
    display: block;
    position: relative;
    color: #000;
    font-size: 20px;
    font-weight: 700;
    line-height: 17px;
    top: -20px;
}

.home-page-section__about-p3-text-2 {
    display: block;
    position: relative;
    color: #000000a3;
    font-size: 15px;
    top: -10px
}

/* section 3 */

.home-page-section-achievements {
    position: relative;
    display: block;
    padding: 80px;
    color: #fff;

    /* background-color: #000; */
    background-image: linear-gradient(to left bottom, #000000db 49%, #fff0 100%), linear-gradient(#00000080 0%, #00000080 100%), url("../../../assets/background6.jpg");
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: left 0px top 0px, left 0px top 0px, center center;
    background-size: auto, auto, cover;
    background-attachment: scroll, scroll, fixed;
    height: auto;

}

.home-page-section-achievements-2 {
    position: relative;
    display: block;
}

.home-page-section-achievements-title {
    margin-bottom: 50px;
}

.home-page-section-achievements-title-1 {
    display: inline-block;
    margin-right: 25px;
    max-width: -moz-available;
}

.home-page-section-achievements-title-1 span{
    font-weight: 700;
    color: #fff;
    font-size: 46px;
    line-height: 68px;
}

.home-page-section-achievements-title-2 {
    display: inline-block;
    position: relative;
    top: -10px;
}


.home-page-section-achievements-title-2 span{
    font-size: 20px;
    line-height: 21px;
    font-weight: 700;
    color: #c7000f;
}

@media screen and (max-width: 1364px){
    .home-page-section-achievements-title-2 {
        top: 5px;
    }
}

.home-page-section-achievements-list {
    display: inline-grid;
    grid-template-columns: auto auto auto;
}

.home-page-section-achievements-achievement {
    position: relative;
    display: inline-block;
    width: auto;
    margin-right: 5.6vw;
}

.home-page-section-achievements-achievement-img {
    height: 333px;
}

.home-page-section-achievements-achievement img{
    filter: saturate(0%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.home-page-section-achievements-achievement-text {
    text-align: left;
    max-height: 130px;
    height: 130px;
    margin-top: 30px;
}
/* section 5  */

.home-page--new-section-stages {
    height: 535px;
    display: block;
    position: relative;
    padding: 80px;

    background-image: url("/src/assets/background8.png"), linear-gradient(#000000db 0%, #000000db 100%),url("/src/assets/background7.png");
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: left -56px top -126px, left 0px top 0px, center center;
    background-size: 700.00px auto, auto, cover;
    background-attachment: scroll, scroll, fixed;

    /* background-image: url("/src/assets/background8.png"), linear-gradient(#000000db 0%, #000000db 100%),url("/src/assets/background7.png");
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: left -56px top -126px, left 0px top 0px, center center;
    background-size: 700.00px auto, auto, cover;
    background-attachment: scroll, scroll, fixed; */
}

.home-page--new-section-stages-2 {
    position: relative;
    display: block;
    width: 85%;
    height: auto;
}

.home-page--new-section-stages-title {
    display: block;
    position: relative;
    margin-bottom: 67px;
}

.home-page--new-section-stages-title-1 {
    display: inline-block;
    max-width: -moz-available;
}

.home-page--new-section-stages-title-1 span{
    font-weight: 700;
    color: #fff;
    font-size: 68px;
    line-height: 68px;
}

.home-page--new-section-stages-title-2 {
    display: inline-block;
    position: absolute;
    width: 825px;
    font-weight: 400;
    text-transform: none;
    font-size: 16px;
    color: #ffffff8a;
    margin-top: 25px;
    line-height: normal;
    padding-left: 40px;
}

@media screen and (max-width: 1630px){
    .home-page--new-section-stages-title-2 {
        display: block;
        padding-left: 0px;

    }
}

.home-page--new-section-stages-title-2 span{
    font-weight: 400;
    text-transform: none;
    font-size: 16px;
    color: #ffffff8a;
    line-height: normal;

}

.home-page--new-section-stages-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    width: 100%;
}

.home-page--new-section-stages-grid-element {
    display: inline-block;
    position: relative;
    color: #fff;
    width: auto;
    height: 230px;
}

.home-page--new-section-stages-grid-element-icon {
    display: inline-block;
    height: 100%;
    margin-right: 20px;
}

.home-page--new-section-stages-grid-element-icon svg{
    width: 50px;
    height: 50px;
    fill: #fdfdfd;
}

.home-page--new-section-stages-grid-element-text {
    display: inline-block;
    position: absolute;
    
}

.home-page--new-section-stages-grid-element-text-index span{
    font-weight: 700;
    font-size: 56px;
    color: #c7000f;
    line-height: 40px;
}
.home-page--new-section-stages-grid-element-text-title{
    position: relative;
    top: 10px;
}

.home-page--new-section-stages-grid-element-text-title span{
    font-size: 21px;
    font-weight: 500;
    color: #fff;
    text-transform: none;
    line-height: 23px;
}

.home-page--new-section-stages-grid-element-text-description {
    width: auto;
    font-weight: 400;
    text-transform: none;
    font-size: 16px;
    color: #ffffff8a;
    line-height: normal;
}

/* adaptive */
@media screen and (max-width: 1000px) {
    .product-section-list {
        grid-template-columns: auto auto;
        text-align: center;
    }

    .home-page--section__title-2-title span{
        font-size: 5vw;
    }
    /* Title */
    .home-page--section__title-2-title {
        display: block;
        text-align: center;
    }

    .home-page--section__title-2-subtitle {
        display: block;
        text-align: center;
    }

    .home-page--section__title-2-subtitle span{
        font-size: 5vw;
        line-height: 45px;
    }

    .home-page--section__title-2-tagline {
        text-align: center;
    }

    .home-page--section__title-2-work-time-icon {
        display: none;
    }

    .home-page--section__title-2-work-time-text {
        display: block;
        text-align: center;
    }

    .home-page--section__title-2-work-time-feedback-button {
        display: block;
        text-align: center;
        scale: 1.3;
        margin-top: 10px;
    }

    .home-page__rules-0 {
        grid-template-columns: none;
    }

    .home-page__rules-item {
        display: block;
        margin-top: 20px;
        text-align: center;
    }

    .home-page__rules-item-counter {
        display: block;
    }

    .home-page__rules {
        margin-top: -200px;
    }

    /* home-page-section__about adaptive */
    
    .home-page-section__about-2 {
        display: block;
        
    }

    .home-page-section__about-p2 {
        display: none;
    }

    .home-page-section__about-p1 {
        display: block;
        max-width: none;
        margin: 0;
    }

    .home-page-section__about-p1-title {
        display: block;
        text-align: center;
    }
    
    .home-page-section__about-p1-title-2 {
        text-align: center;
    }

    .home-page-section__about-p1-title-3 {
        margin-top: 15px;
        text-align: center;
    }

    .home-page-section__about-p1-button {
        text-align: center;
    }

    .home-page-section__about-p3-item {
        text-align: center;
        display: block;
    }

    .home-page-section__about-p3-text {
        display: block;
        width: 100%;
        margin-top: 20px;
    }

    .home-page-section__about-p3 {
        max-width: none;
    }  

    .home-page-section__about-p1-work-time {
        text-align: center;
    }

    .home-page-section__about-p1-work-time-icon {
        margin: 0;
    }

    .home-page-section__about-p1-work-time-texts {
        display: block;
        text-align: center;
        margin-bottom: 15px;
    }
    
    .home-page--new-section-stages {
        height: auto;
    }

    .home-page--new-section-stages-grid {
        grid-template-columns: auto;
    }

    /* stages item */
    .home-page--new-section-stages-grid-element-icon {
        display: block;
        height: 50px;
        text-align: center;
        margin-right: 0;
    }

    .home-page--new-section-stages-grid-element-text {
        position: relative;
        width: 100%;
    }

    .home-page--new-section-stages-grid-element-text-index {
        display: block;
        margin-top: 5px;
        text-align: center;
    }
    
    .home-page--new-section-stages-grid-element-text-index span{
        font-size: 30px;
        color: #88898a;
    }

    .home-page--new-section-stages-grid-element-text-title {
        display: block;
        text-align: center;
    }

    .home-page--new-section-stages-grid-element-text-description {
        display: block;
        text-align: center;
    }

    .home-page--new-section-stages-title {
        text-align: center;
    }

    .home-page--new-section-stages-title-2 {
        display: block;
        position: relative;
        width: 100%;
    }

    .feedback-btn {
        margin-left: 0;
    }

}

@media screen and (max-width: 550px){

    .home-page--section__title-2-title span{
        line-height: 25px;
        font-size: 7vw;
    }

    .home-page--section__title-2-subtitle span {
        line-height: 33px;
        font-size: 7vw;
    }

    .product-section-list {
        grid-template-columns: auto;
    }

    .home-page-section__products-title {
        font-size: 40px;
    }

    .home-page-section-achievements-list {
        position: relative;
        width: 100%;
        grid-template-columns: auto;
    }


    .home-page-section-achievements-achievement {
        margin-right: 0;
        margin-bottom: 25px;
    }

    .marquee-partners-title {
        font-size: 28px;
    }

    
}