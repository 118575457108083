.partner-element {
    width: 233px;
    margin-right: 60px;
    margin-bottom: 20px;
}

.partner-element-logo {
    border: 5px #fafafa solid;
}

.partner-element-description {
    color: #83838399;
    line-height: normal;
    font-size: 13px;
    text-decoration: underline dashed;
}

.marquee-partners-title{
    font-weight: 700;
    font-size: 7vw;
    line-height: 68px;
    margin-bottom: 50px;
}

.marquee-partners {
    padding: 80px 80px 50px 80px;
}
