ul {
    display: block;
    position: relative;
    list-style: none;
    /* margin-top: 20px; */
    padding-top: 25px;
    /* height: 700px;  */
    height: 50px;
    padding-left: 0px;
}

.nav_bar_background {
    background-image: url("/src/assets/background3.png");
    background-position: top -150px left 0px;
    padding: 15px 80px 15px 80px;
}

li {
    display: inline;
    margin-right: 50px;    
}

a {
    color: #fff;
    font-style: normal;
    text-decoration: none;
    font-weight: bold;
}

a:hover {
    color: #888888;
}

.nav_button {
    display: none;
    position: fixed;
    top: 0px;
    left: 0;
} 

@media screen and (max-width: 1000px) {
    .nav_list {
        display: none;
    }

    .nav_button {
        display: inline-block;
    }
}