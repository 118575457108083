.service-categories-page-content {
    padding: 35px;

}

.service-categories-page-content h1 {
    display: block;
}

.service-categories-page-content-items {
    display: grid;
    grid-template-columns: auto auto auto auto; 
    min-height: 700px;
}

@media screen and (max-width: 1725px) {
    .service-categories-page-content-items {
        grid-template-columns: auto auto auto;
    }
    
}

@media screen and (max-width: 1350px) {
    .service-categories-page-content-items {
        grid-template-columns: auto auto;
    }
    
}

@media screen and (max-width: 900px) {
    .service-categories-page-content-items {
        grid-template-columns: auto;
    }
    
}

.service-categories-page-content-item {
    display: inline-block;
    margin: 10px;
    max-width: 400px;
    min-width: 400px;
}

.service-categories-page-content-item-image {
    max-width: 100%;
    max-height: 266px;
    min-height: 266px;
}

.service-categories-page-content-item-image img {
    width: 100%;
    max-height: 266px;
    min-height: 266px;
    object-fit: cover;
}

.service-categories-page-content-item-title {
    text-align: center;
    margin-top: 20px;
}

.service-categories-page-content-item-title a {
    text-align: center;
    color: #000;
}







