
.home-page--feedback-carousel {
    display: block;
    position: relative;
    height: 700px;
    padding: 80px;
    
    background-color: #f5f5f5;
    box-shadow: 0px 0px 56px 1px #918e871c;
    background-image: url("/src/assets/background9.jpg"), linear-gradient(#ffffffe3 0%, #ffffffe3 100%);
    background-repeat: no-repeat, no-repeat;
    background-position: center center, left 0px top 0px;
    background-size: cover, auto;
    background-attachment: scroll, scroll;
    
}


.home-page--feedback-carousel-2 {
    position: relative;
    display: block;
    height: 700px;
}

.home-page--feedback-carousel-title {
    display: inline-block;
    position: relative;
    font-weight: 700;
    font-size: 80px;
    line-height: 68px;
    margin-bottom: 46px;
}

.feedback-box {
    position: relative;
    display: inline-block;
    max-width: 420px;
    min-width: 420px;
    max-height: 420px;
    height: 420px;
    background-color: #fff;
    margin-left: 20px;
    margin-top: 20px;
    color: #0000007d;
    fill: #0000007d;
    padding: 10px;

    border-radius: 10px;

    box-shadow: 12px 8px 17px -10px rgba(0,0,0,0.67);
    -webkit-box-shadow: 12px 8px 17px -10px rgba(0,0,0,0.67);
    -moz-box-shadow: 12px 8px 17px -10px rgba(0,0,0,0.67);

    /* -webkit-box-shadow: 13px 10px 52px -9px rgba(0,0,0,0.75);
    -moz-box-shadow: 13px 10px 52px -9px rgba(0,0,0,0.75);
    box-shadow: 13px 10px 52px -9px rgba(0,0,0,0.75); */

    scale: 0.85;
}

.feedback-box-user-name {
    position: absolute;
    display: inline-block;
    color: #000;
    /* top: 20px; */
    margin-top: 20px;
    margin-left: 25px;
}

.feedback-box-user-name {
    font-weight: bold;
}

.feedback-box-user-icon {
    display: inline-block;
    width: 55px;
    height: 55px;
    margin: 30px1;
}

.feedback-box-user-icon img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.feedback-box-icon {
    margin-top: 10px;
    margin-bottom: 10px;
}

.feedback-box-icon svg {
    width: 30px;
    height: 30px;
}

@media screen and (max-width: 550px){
    .home-page--feedback-carousel {
        padding: 35px;
    }
    
    .feedback-box {
        scale: 0.8;
    }
}